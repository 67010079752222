import React, { useState } from 'react';
import './GeneralContact.css';
import { FaEnvelope, FaPhone } from 'react-icons/fa';
import valenciaImage from '../images/valencia.jpg';
import valenciaMap from '../images/valencia-map.png';
import { useTranslation } from 'react-i18next';

const GeneralContact = () => {
  const { t, i18n } = useTranslation();
  let language = '';
  if (i18n.language === 'es') {
    language = 'es';
  } else if (i18n.language === 'en') {
    language = 'en';
  } else {
    language = navigator.language.split('-')[0];
  }

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    inquiry: '',
  });

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/send-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        setFormSubmitted(true);
      } else {
        alert('Failed to send message');
      }
    } catch (error) {
      alert('Error: ' + error.toString());
    }
  };

  return (
    <div className="contact-container">
      <div className="contact-info">
        <div className="contact-item">
          <FaEnvelope className="icon" />
          <span>guillermollopis@protforge.com</span>
        </div>
        <div className="contact-item">
          <FaPhone className="icon" />
          <span>+34 636873210</span>
        </div>
      </div>
      <div className="contact-form">
        {formSubmitted ? (
          <div className="success-message">
            <h2>{t('general_contact.message_sent')}</h2>
            <p>{t('general_contact.next_days_reply')}</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">{t('general_contact.name')}</label>
              <input type="text" id="name" name="name" onChange={handleChange} />
            </div>
            <div className="form-group">
              <label htmlFor="email">{t('general_contact.email')}</label>
              <input type="email" id="email" name="email" onChange={handleChange} />
            </div>
            <div className="form-group">
              <label htmlFor="inquiry">{t('general_contact.inquiry')}</label>
              <textarea id="inquiry" name="inquiry" onChange={handleChange}></textarea>
            </div>
            <button type="submit">{t('general_contact.submit')}</button>
          </form>
        )}
      </div>
      <div className="location-info">
        <h2>{t('general_contact.location')}</h2>
        <div className="location-content">
          <div className="location-image">
            <img src={valenciaImage} alt="Valencia, Spain" />
            <div className="location-text">Edificio Lanzadera, La Marina de, Carrer del Moll de la Duana, s/n, 46024 Valencia</div>
          </div>
          <div className="location-map">
            <img src={valenciaMap} alt="Map of Valencia" />
            <a href="https://www.google.com/maps/place/Lanzadera/@39.4622222,-0.3306305,17z/data=!3m2!4b1!5s0xd60485cd0699da7:0xcf810d6533333ca0!4m6!3m5!1s0xd60488f372defa3:0xcafa5cd672a8178!8m2!3d39.4622222!4d-0.3280556!16s%2Fg%2F11b6nvyn8t?entry=ttu" target="_blank" rel="noopener noreferrer" className="map-button">{t('general_contact.open_map')}</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralContact;
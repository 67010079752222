// src/pages/CaseStudies.js
import React from 'react';
import './CaseStudies.css';
import biosimilarOptimizationPDF1 from '../pdfs/in_silico_optimization.pdf';
import biosimilarOptimizationPDF1Es from '../pdfs/in_silico_optimization_es.pdf';
import deNovoDesignPDF from '../pdfs/in_silico_design_report.pdf';
import deNovoDesignPDFEs from '../pdfs/de_novo_optimization_es.pdf';
import { useTranslation } from 'react-i18next';

const CaseStudies = () => {
  const { t, i18n } = useTranslation();
  let language = '';
  if (i18n.language === 'es') {
    language = 'es';
  } else if (i18n.language === 'en') {
    language = 'en';
  } else {
    language = navigator.language.split('-')[0];
  }

  const biosimilarInsilicoPDF = language === 'es' ? biosimilarOptimizationPDF1Es : biosimilarOptimizationPDF1;
  const denovoInsilicoPDF = language === 'es' ? deNovoDesignPDFEs : deNovoDesignPDF;

  return (
    <div className="case-studies-container">
      <div className="case-study-block">
        <a href={biosimilarInsilicoPDF} target="_blank" rel="noopener noreferrer" className="case-study-title">
          {t('case_studies.optimization_insilico')}
        </a>
        <p className="case-study-description">{t('case_studies.optimization_explanation')}</p>
      </div>
      <div className="case-study-block">
        <a href={denovoInsilicoPDF} target="_blank" rel="noopener noreferrer" className="case-study-title">
          {t('case_studies.denovo_insilico')}
        </a>
        <p className="case-study-description">{t('case_studies.denovo_explanation')}</p>
      </div>
      <div className="case-study-block">
        <h2 className="case-study-title">{t('case_studies.our_pipeline_title')}</h2>
        <p className="case-study-description">
          {t('case_studies.our_pipeline_description')}
        </p>
      </div>
    </div>
  );
};

export default CaseStudies;

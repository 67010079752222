import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../images/company-logo.png';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t, i18n } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const navRef = useRef(null);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setOpenDropdown(null); // Close all dropdowns when toggling the main menu
  };

  const toggleDropdown = (dropdown) => {
    if (openDropdown === dropdown) {
      setOpenDropdown(null); // Close dropdown if it's already open
    } else {
      setOpenDropdown(dropdown); // Open the selected dropdown
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMenuOpen && navRef.current && !navRef.current.contains(event.target)) {
        setIsMenuOpen(false);
        setOpenDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  const handleLinkClick = () => {
    setIsMenuOpen(false);
    setOpenDropdown(null);
  };

  const handleMouseEnter = (dropdown) => {
    if (window.innerWidth > 768) {
      setOpenDropdown(dropdown);
    }
  };

  const handleMouseLeave = () => {
    if (window.innerWidth > 768) {
      setOpenDropdown(null);
    }
  };

  return (
    <header>
      <div className="logo-title">
        <img src={logo} alt="ProtForge Logo" />
        <h1><Link to="/">ProtForge</Link></h1>
      </div>
      <nav ref={navRef} className={isMenuOpen ? 'open' : ''}>
        {isMenuOpen && (
          <div className="mobile-menu-header">
            <Link to="/" onClick={handleLinkClick}>
              <img src={logo} alt="ProtForge Logo" className="mobile-menu-logo" />
              <h1>ProtForge</h1>
            </Link>
          </div>
        )}
        <ul className="nav-links">
          <li className="dropdown" onMouseEnter={() => handleMouseEnter('aboutUs')} onMouseLeave={handleMouseLeave}>
            <button className="dropbtn" onClick={() => toggleDropdown('aboutUs')}>
              {t('header.about_us')}
            </button>
            {(openDropdown === 'aboutUs' || (isMenuOpen && openDropdown === 'aboutUs')) && (
              <div className="dropdown-content">
                <Link to="/about" onClick={handleLinkClick}>{t('header.about_us')}</Link>
                <Link to="/about/our-blog" onClick={handleLinkClick}>{t('header.our_blog')}</Link>
              </div>
            )}
          </li>
          <li className="dropdown" onMouseEnter={() => handleMouseEnter('ourTools')} onMouseLeave={handleMouseLeave}>
            <button className="dropbtn" onClick={() => toggleDropdown('ourTools')}>
              {t('header.our_tools')}
            </button>
            {(openDropdown === 'ourTools' || (isMenuOpen && openDropdown === 'ourTools')) && (
              <div className="dropdown-content">
                <Link to="/our-tools#de-novo-design" onClick={handleLinkClick}>{t('header.denovo_design')}</Link>
                <Link to="/our-tools#biosimilars" onClick={handleLinkClick}>{t('header.biosimilars')}</Link>
                <Link to="/our-tools#optimization" onClick={handleLinkClick}>{t('header.optimization')}</Link>
              </div>
            )}
          </li>
          <li><Link to="/case-studies" onClick={handleLinkClick}>{t('header.case_studies')}</Link></li>
          <li className="dropdown" onMouseEnter={() => handleMouseEnter('contact')} onMouseLeave={handleMouseLeave}>
            <button className="dropbtn" onClick={() => toggleDropdown('contact')}>
              {t('header.contact')}
            </button>
            {(openDropdown === 'contact' || (isMenuOpen && openDropdown === 'contact')) && (
              <div className="dropdown-content">
                <Link to="/contact/general" onClick={handleLinkClick}>{t('header.general_contact')}</Link>
                <Link to="/contact/collaboration" onClick={handleLinkClick}>{t('header.ask_for_a_collaboration')}</Link>
              </div>
            )}
          </li>
        </ul>
      </nav>
      <div className="language-dropdown">
        <button className="dropbtn">{t('header.language')}</button>
        <div className="dropdown-content">
          <button onClick={() => changeLanguage('en')}>English</button>
          <button onClick={() => changeLanguage('es')}>Español</button>
        </div>
      </div>
      <button className="hamburger" onClick={toggleMenu}>
        &#9776;
      </button>
    </header>
  );
}

export default Header;
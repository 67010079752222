import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <div className="footer-bar">
        <Link to="/privacy-policy">{t('footer.privacy_policy')}</Link>
        <div className="social-icons">
          <a href="https://www.linkedin.com/company/protforge/about/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin"></i>
          </a>
          <Link to="/contact/general">
            <i className="fas fa-envelope"></i>
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;